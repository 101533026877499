import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {
    AppBar, Avatar,
    Box,
    Button, CircularProgress,
    FormControl, Pagination, PaginationItem,
    Paper, Tab, Tabs, Typography,
    useMediaQuery, useTheme,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import {PersonType} from "../../store/store";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import TabContainer from "./TabContainer/TabContainer";
import avatarImg from "../../assets/avatar.png";
import EditPersonForm from "../../components/EditPersonForm";
import AddReportForm from "../../components/AddReportForm";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

const ViewPage: React.FC = () => {
    const {store} = useContext(Context)
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const isMobile=false

    return (
        <Paper sx={{
            position: "relative",
            maxWidth: "90vw",
            margin: '2rem auto',
            padding: '2rem',
            borderRadius: '20px',
            background: '#FFF',
            boxShadow: '0px 0px 50px 0px rgba(64, 63, 63, 0.30)',
            boxSizing: 'border-box',
        }}>
        <div>
            <AppBar position="static"
                    sx={{
                        backgroundColor:'#FFF',
                        border:'none',
                        boxShadow:'none',
                    }}
            >
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"
                sx={{
                    color:'#000',
                    backgroundColor:'#FFF',
                    border:'none',
                    boxShadow:'none',
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#000',
                    },
                }}
                >
                    <Tab label="General" />
                    <Tab label="Recognition" />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Box
                sx={{
                    display:'flex'
                }}
                >
                    <Avatar alt="Photo" src={store.person.Exension?
                        `data:image/${store.person.Exension};base64,${store.person.Image}`:avatarImg
                    }
                            sx={{
                                width: isMobile ? "245px" : "245px",
                                height: isMobile ? "245px" : "245px",
                                marginBottom: 1,
                            }}
                    />
                    <EditPersonForm />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AddReportForm/>
            </TabPanel>
        </div>
        </Paper>
    );
};

export default observer(ViewPage);
