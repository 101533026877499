import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme";
import {Rout} from "./router/router";
import Store from "./store/store";

interface State {
    store: Store,
}

const store = new Store();

export const Context = createContext<State>({
    store,
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Context.Provider value={{store}}>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Rout/>
        </ThemeProvider>
    </Context.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
