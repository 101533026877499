import React from 'react';
import MainPage from "../pages/MainPage";
import Login from "../pages/Login";
import AddPersonPage from "../pages/AddPersonPage";
import PersonInfoPage from "../pages/PersonInfoPage";
import Reports from "../pages/adminPages/Reports";
import ViewPage from "../pages/adminPages/ViewPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export function Rout() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage />}/>
                {/*<Route path="/Login" element={<Login />}/>*/}
                <Route path="/add" element={<AddPersonPage />}/>
                <Route path="/person" element={<PersonInfoPage />}/>
                {/*<Route path="/reports" element={<Reports />}/>*/}
                {/*<Route path="/view" element={<ViewPage />}/>*/}
            </Routes>
        </Router>
    )
}
