import React, {FC, useContext} from 'react';
import {
    Box,
    Button,
    Paper,
    Typography,
    useMediaQuery, useTheme,
} from "@mui/material";
import AddPersonForm from "../components/AddPersonForm";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import cloud from "../assets/cloud.png";
import backgroundImg from "../assets/backgroundImg.png";
import {Context} from "../index";
import flagIsrael from "../assets/flagIsrael.svg";
import changeIcon from "../assets/change.svg";
import flagUk from "../assets/flagUK.svg";


const AddPersonPage: FC = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const changeLanguageHandler = () => {
        store.setIsEnglish(!store.isEnglish)
    }
    const goToMainPage = () => {
        navigate(-1);
    }
    return (
        <Paper sx={{
            fontFamily: 'Inter',
            display: "flex",
            justifyContent: 'center',
            minWidth: "100%",
            minHeight: 'calc(100vh - 4rem)',
            padding: isMobile ? 'unset' : '2rem',
            borderRadius: isMobile ? 'none' : '40px',
            background: '#EDEDED',
            color: "#4D4D4D",
        }}>
            <Box sx={{
                position: "relative",
                maxWidth: "1685px",
                minHeight: 'calc(100vh - 4rem)',
                margin: isMobile ? "unset" : "1 auto",
                background: "rgba(255, 255, 255, 0.20)",
                borderRadius: isMobile ? 'none' : '40px',
                backgroundImage: `url(${cloud})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}>
                <div style={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: isMobile ? "unset" : "center",
                    flexWrap: "wrap",
                    width: "100%",
                    height: "100%",
                    borderRadius: "30px",
                    border: "6px solid rgba(125, 125, 125, 0.20)",
                    padding: isMobile ? "10px 80px" : "40px 80px",
                    backgroundImage: `url(${backgroundImg}`,
                    backgroundSize: "cover",
                    backdropFilter: "blur(200px)",
                }}>
                    <Box sx={{
                        display: isMobile ? "unset" : "flex",
                        alignItems: "center",
                        minWidth: isMobile ? "100%" : '300px',
                        maxWidth: isMobile ? "100%" : '550px',
                        maxHeight: isMobile ? "unset" : "800px",
                        boxSizing: 'border-box',
                        padding: 0,
                        marginLeft: 2,
                        marginRight: 2,
                    }}>

                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                        }}>
                            <Box sx={{
                                display: "flex",
                                justifyContent: store.isEnglish?'start':'end',
                                padding: "0 10px",
                                width: "100%",
                                marginBottom: "20px",
                            }}>
                                <Box sx={{
                                    width: isMobile ? '100%':'191px',
                                    height: '48px', borderRadius: '10px', border: '1px solid #4D4D4D',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    transform: store.isEnglish ? 'unset' : 'scaleX(-1);',
                                    transition: '0.3s',
                                }} onClick={changeLanguageHandler}>
                                    <img style={{margin: '10px'}} src={flagIsrael} alt="flagIsrael"/>
                                    <img style={{margin: '10px'}} src={changeIcon} alt="change"/>
                                    <img style={{margin: '10px'}} src={flagUk} alt="flagUk"/>
                                </Box>
                            </Box>
                            {store.isEnglish ?
                                <Box sx={{marginRight: isMobile ? "unset" : "30px"}}>
                                    <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant="h5"
                                                color="text.secondary"
                                                sx={{fontSize: isMobile ? "30" : "36px"}}>
                                        <b>Create new person for searching</b>
                                    </Typography>
                                    <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant="body1"
                                                color="text.secondary"
                                                sx={{
                                                    marginTop: "30px"
                                                }}>
                                        Please fill out the following form to create a new person for searching on our
                                        platform. We understand the urgency and importance of finding missing
                                        individuals in Israel, especially in light of the events that took place on 7th
                                        October. By providing detailed information about the missing person, we can
                                        assist in the search efforts and ultimately help reunite them with their loved
                                        ones. Your collaboration is essential in our mission to bring hope and closure
                                        to the families affected during this challenging time. Together, let's work
                                        towards a swift and successful resolution.
                                    </Typography>
                                </Box> :
                                <Box sx={{marginRight: isMobile ? "unset" : "30px"}}>
                                    <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant="h5"
                                                color="text.secondary"
                                                sx={{fontSize: isMobile ? "30" : "36px"}}>
                                        <b>צור דוח נעדר חדש לחיפוש</b>
                                    </Typography>
                                    <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant="body1"
                                                color="text.secondary"
                                                sx={{
                                                    marginTop: "30px"
                                                }}>
                                        אנא מלא את הטופס הבא כדי ליצור נעדר חדש לחיפוש בפלטפורמה שלנו. אנו מבינים את
                                        הדחיפות והחשיבות של מציאת נעדרים בישראל, במיוחד לאור האירועים שהתרחשו ב-7
                                        באוקטובר. על ידי מתן מידע מפורט על הנעדר, נוכל לסייע במאמצי החיפוש ובסופו של דבר
                                        לעזור לאחד אותם עם יקיריהם. שיתוף הפעולה שלכם חיוני במשימה שלנו להביא תקווה
                                        וסגירות מעגל למשפחות שנפגעו בתקופה מאתגרת זו. יחד, בואו נעבוד למען פתרון מהיר
                                        ומוצלח.
                                    </Typography>
                                </Box>}
                        </Box>
                    </Box>

                    <Box sx={{
                        position: "relative",
                        maxWidth: "450px",
                        width: isMobile ? '100%' : '90%',
                        marginTop: 3,
                        marginBottom: 3,
                    }}>
                        <AddPersonForm callBack={goToMainPage}/>

                    </Box>
                </div>
            </Box>
        </Paper>
    );
}

export default observer(AddPersonPage);
