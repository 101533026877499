import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {
    Box,
    Button, CircularProgress,
    FormControl, Pagination, PaginationItem,
    Paper,
    useMediaQuery, useTheme,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import {PersonType} from "../../store/store";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import TabContainer from "./TabContainer/TabContainer";

const Reports: FC = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [searchValue, setSearchValue] = useState(store.searchValue);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const pagesCount = Math.ceil(store.usersCount / 20)
    const personHandler = (person: PersonType) => {
        store.setPerson(person)
        store.setSearchValue(searchValue)
        navigate('/person')
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        // Устанавливаем новый таймер
        timerRef.current = setTimeout(() => {
            store.getPersons(event.target.value, 0);
        }, 1000);
    };


    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        store.setPage(page);
        store.getPersons(searchValue, store.page)
    };

    const logOutHandler=()=>{
        store.setIsAdmin(false)
        navigate('/')
    }

    const goToAddPage = () => {
        navigate('/add')
    }

    useEffect(()=>{
        if (!store.isAdmin) navigate('/')
    },[])

    return (
        <Paper sx={{
            position: "relative",
            maxWidth: "90vw",
            minHeight: 'calc(100vh - 4rem)',
            margin: '2rem auto',
            padding: '2rem',
            borderRadius: isMobile ? 'none' : '20px',
            background: '#FFF',
            boxShadow: '0px 0px 50px 0px rgba(64, 63, 63, 0.30)',
            boxSizing: 'border-box',
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: isMobile ? "center" : "end",
                margin: isMobile ? "0 auto" : '20px 0',
            }}>
                <Button onClick={logOutHandler} variant="text" sx={{textTransform: 'none', fontWeight: 600, fontSize: '16px'}}>Log Out</Button>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: isMobile ? "center" : "space-between",
                margin: isMobile ? "0 auto" : '10px 0 30px 100px',
            }}>
                <FormControl sx={{width: isMobile ? "70vw" : "400px",}} variant="outlined">

                    <input style={{
                        width: isMobile ? "70vw" : "400px",
                        height: isMobile ? "50px" : "50px",
                        padding: "15px 20px",
                        fontSize: "16px",
                        borderRadius: "10px",
                        border: "1px solid #A9B1C3",
                    }}
                           placeholder='הכנס שם פרטי, שם משפחה'
                           id="outlined-search"
                           value={searchValue}
                           onChange={handleSearch}
                           dir="rtl"
                    />
                    <label htmlFor="outlined-search"
                           style={{
                               textAlign: 'center',
                               position: 'absolute',
                               padding: '0 10px',
                               top: '-10px',
                               right: '40px',
                               background: 'white',
                               width: 'auto',
                               color: '#787878',
                               fontFamily: 'Inter',
                               fontSize: '12px',
                               fontStyle: 'normal',
                               fontWeight: '400',
                               lineHeight: 'normal',
                           }}
                    >חיפוש</label>
                </FormControl>
                <Button variant="contained"
                        onClick={goToAddPage}
                        sx={{
                            position: isMobile ? "fixed" : "unset",
                            top: isMobile ? 0 : "unset",
                            width: isMobile ? "100vw" : "195px",
                            height: "50px",
                            borderRadius: isMobile ? "0" : "10px",
                            textTransform: "none",
                            zIndex: isMobile ? 10 : "unset"
                        }}>הוסף דוח</Button>
            </Box>
            <Box sx={{minHeight: '70vh', position:'relative'}}>
                {store.isLoading ?
                    <Box sx={{
                        position:'absolute',
                        top:'45%',
                        left: 'calc(50% - 15px)'
                    }}>
                        <CircularProgress/>
                    </Box> :
                    <TabContainer/>}
            </Box>
            <Box sx={{
                display: store.isLoading ? "none" : "flex",
                justifyContent: isMobile ? "center" : "end",
                margin: '10px'
            }}>
                <Pagination showFirstButton
                            showLastButton
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        first: LastPageIcon,
                                        last: FirstPageIcon,
                                        previous: ChevronRightIcon,
                                        next: ChevronLeftIcon
                                    }}
                                    {...item}/>)}
                            sx={{direction: 'rtl'}} page={store.page} count={pagesCount} shape="rounded"
                            onChange={handlePageChange}
                />
            </Box>
            <Box dir="rtl"
                 sx={{
                     display: "flex",
                     justifyContent: isMobile ? "center" : "start",
                     marginRight: isMobile ? "unset" : 2,
                     fontSize: "14px"
                 }}>
                נמצאו {store.usersCount} אנשים
            </Box>
        </Paper>
    );
}

export default observer(Reports);
