import React, {ChangeEvent, FC, useContext, useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia, CircularProgress, FormControl, Pagination, PaginationItem,
    Paper,
    TextField,
    Typography, useMediaQuery, useTheme,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import avatarImg from "../assets/avatar.png";
import cloud from "../assets/cloud.png";
import visitImg from "../assets/visitImg.png";
import backgroundImg from "../assets/backgroundImg.png";
import {PersonType} from "../store/store";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import flagIsrael from "../assets/flagIsrael.svg";
import changeIcon from "../assets/change.svg";
import flagUk from "../assets/flagUK.svg";
import s from "./MainPage.module.css";

const MainPage: FC = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState(store.searchValue);
    const [isDefaultValue, setIsDefaultValue] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const pagesCount = Math.ceil(store.usersCount / 20)
    const users = store.users

    const changeLanguageHandler = () => {
        store.setIsEnglish(!store.isEnglish)
    }

    const personHandler = (person: PersonType) => {
        store.setPerson(person)
        store.setSearchValue(searchValue)
        navigate('/person')
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        // Устанавливаем новый таймер
        timerRef.current = setTimeout(() => {
            store.getPersons(event.target.value, 0);
        }, 1000);
    };


    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        store.setPage(page);
        store.getPersons(searchValue, store.page)
    };

    const goToAddPage = () => {
        navigate('/add')
    }

    useEffect(() => {
        if (!store.users.length) {
            store.getPersons("", 1)
        }
    }, [])

    return (
        <Paper sx={{
            fontFamily: 'Century Gothic',
            display: "flex",
            justifyContent: 'center',
            minWidth: "100%",
            minHeight: 'calc(100vh - 4rem)',
            padding: isMobile ? 'unset' : '75px',
            borderRadius: isMobile ? 'none' : '40px',
            background: '#EDEDED',
            color: "#4D4D4D",
        }}>
            <Box sx={{
                position: isMobile ? "relative" : "relative",
                maxWidth: isMobile ? "100%" :"1685px",
                minHeight: 'calc(100vh - 4rem)',
                margin: isMobile ? "0" : "1 auto",
                background: "rgba(255, 255, 255, 0.20)",
                backgroundImage: `url(${cloud})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: isMobile ? 'none' : '40px',
            }}>
                <div style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "30px",
                    border: "6px solid rgba(125, 125, 125, 0.20)",
                    padding: isMobile ? '45px 10px' : "45px 80px",
                    backgroundImage: `url(${backgroundImg}`,
                    backgroundSize: "cover",
                    backdropFilter: "blur(200px)",
                }}>
                    {/*inbox*/}
                    <Box sx={{
                        display: "flex",
                        justifyContent: 'end',
                        padding: "0 10px",
                        width: "100%",
                    }}>
                        <Box sx={{
                            width: isMobile ? '100%':'191px',
                            height: '48px', borderRadius: '10px', border: '1px solid #4D4D4D',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transform: store.isEnglish ? 'unset' : 'scaleX(-1);',
                            transition: '0.3s',
                        }} onClick={changeLanguageHandler}>
                            <img style={{margin: '10px'}} src={flagIsrael} alt="flagIsrael"/>
                            <img style={{margin: '10px'}} src={changeIcon} alt="change"/>
                            <img style={{margin: '10px'}} src={flagUk} alt="flagUk"/>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexWrap: isMobile ? "wrap" : 'unset',
                        justifyContent: 'space-between',
                        flexDirection: isMobile ? "column-reverse" : "unset",
                        marginBottom: isMobile ? "40px" : "80px",
                    }}>
                        {store.isEnglish?
                        <Box sx={{marginRight: isMobile ? "unset" : "70px"}}>
                            <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant={"h4"} color="text.secondary"
                                        sx={{
                                            fontSize: isMobile ? "24px" : "36px",
                                            fontWeight: "700",
                                            lineHeight: 'normal',
                                            letterSpacing: isMobile ? "unset" : "1px",
                                            marginBottom: isMobile ? "20px" : "50px",
                                        }}>
                                Bringing Hope and Support: <br/>
                                Helping Families Find Missing Loved Ones in Israel Following the Events of 7th October
                            </Typography>
                            <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant="body1" color="text.secondary"
                                        sx={{
                                            fontSize: "18px",
                                            lineHeight: '135%',
                                        }}
                            >
                                Our platform is dedicated to assisting in the search for missing individuals in Israel following the events of 7th October. We understand the unimaginable anguish that families experience when a loved one goes missing, and we are here to offer support, hope, and resources during this challenging time. With our user-friendly interface and advanced search algorithms, we aim to provide a centralized hub where families can share information, upload images, and collaborate with law enforcement agencies. Our team is determined to leverage the power of technology and community involvement to facilitate a swift and efficient search process. Together, let's bring the missing individuals back home, restore peace to their families, and ensure that no one is left behind.
                            </Typography>
                        </Box>:
                        <Box sx={{marginRight: isMobile ? "unset" : "70px"}}>
                            <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant={"h4"} color="text.secondary"
                                        sx={{
                                            fontSize: isMobile ? "24px" : "36px",
                                            fontWeight: "700",
                                            lineHeight: 'normal',
                                            letterSpacing: isMobile ? "unset" : "1px",
                                            marginBottom: isMobile ? "20px" : "85px",
                                        }}>
                                מביאים תקווה ותמיכה: עוזרים למשפחות למצוא את <br/>
                                יקיריהם הנעדרים בעקבות אירועי ה-7 באוקטובר בישראל
                            </Typography>
                            <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant="body1" color="text.secondary"
                                        sx={{
                                            fontSize: "18px",
                                            lineHeight: '135%',
                                        }}
                            >
                                הפלטפורמה שלנו מוקדשת לסיוע בחיפוש אחר נעדרים בישראל בעקבות אירועי ה-7 באוקטובר. אנו
                                מבינים את הסבל הבלתי נתפס שמשפחות חוות כאשר אדם אהוב נעדר, ואנו כאן כדי להציע תמיכה,
                                תקווה ומשאבים טכנולוגיים בתקופה מאתגרת זו. עם הממשק הידידותי למשתמש ואלגוריתמי החיפוש
                                המתקדמים שלנו, אנו שואפים לספק פלטפורמה מרכזית שבו משפחות יכולות לשתף מידע, להעלות
                                תמונות ולשתף פעולה עם רשויות אכיפת החוק. הצוות שלנו נחוש למנף את הכוח של הטכנולוגיה
                                והמעורבות הקהילתית כדי לאפשר תהליך חיפוש מהיר ויעיל.
                                יחד, בואו נחזיר את הנעדרים הביתה, נחזיר את השקט למשפחותיהם ונבטיח שאף אחד לא יישאר
                                מאחור.
                            </Typography>
                        </Box>
                        }
                        <img src={visitImg} alt="Описание изображения"
                             style={{
                                 marginTop: "25px",
                                 objectFit: 'cover',
                                 width: isMobile ? '100%' : '575px',
                                 maxHeight: "350px",
                                 borderRadius: "30px",
                                 background: "rgba(255, 255, 255, 0.20)",
                                 backdropFilter: "blur(200px)",
                                 marginBottom: isMobile ? "20px" : "unset",
                             }}/>

                    </Box>

                    <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant="h5" color="text.secondary"
                                sx={{
                                    marginBottom: isMobile ? " 40px" :"75px",
                                    marginRight: isMobile ? " unset" :"45px",
                                    fontSize: isMobile ? " 20px" :"30px",
                                    fontWeight: "700",
                                    letterSpacing: isMobile ? " unset" :"1.5px",
                                }}
                    > {store.isEnglish?'Missing people in Israel Following the Events of 7th October':
                    'נעדרים בישראל בעקבות אירועי ה-7 באוקטובר'}
                    </Typography>


                    <Box sx={{
                        display: "flex",
                        width:"100%",
                        justifyContent: isMobile ? "center" : "space-between",
                        margin: isMobile ? "0" : "0 0 40px 0",
                    }}>

                        <FormControl sx={{
                            direction: store.isEnglish?"ltr":"rtl",
                            width: isMobile ? "205px" : "300px",
                            borderColor: 'black',
                            "& .MuiInputLabel-root": {fontSize: '16px'},
                            "& .MuiOutlinedInput-root": {
                                borderRadius: isMobile ? '10px 0px 0px 10px' : "10px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "black", // Цвет обводки при фокусе
                                },
                            },
                        }} variant="outlined">

                            <TextField
                                sx={{width: isMobile ? "205px" :"300px",}}
                                placeholder={store.isEnglish?'Enter first name, last name' :'הכנס שם פרטי, שם משפחה'}
                                id="outlined-search"
                                value={searchValue}
                                onChange={handleSearch}
                                onFocus={() => setIsDefaultValue(false)} // Устанавливаем false, когда TextField получает фокус
                                onBlur={() => {
                                    if (searchValue.trim() === '') {
                                        setIsDefaultValue(true); // Устанавливаем true, если пользователь убирает фокус и введенный текст пуст
                                    }
                                }}
                                InputLabelProps={{shrink: true}}
                                label={store.isEnglish?'Search' :"חיפוש"}
                            />
                        </FormControl>
                        <Button variant="contained"
                                onClick={goToAddPage}
                                sx={{
                                    width: isMobile ? "90px" : "365px",
                                    height: "55px",
                                    borderRadius: isMobile ? '0px 10px 10px 0px' : "10px",
                                    fontSize: isMobile?"11addpx":"16px",
                                    fontWeight: "500",
                                    textTransform: "none",
                                    zIndex: isMobile ? 10 : "unset"
                                }}>{store.isEnglish?'Add report' :'הוסף דוח'}</Button>
                    </Box>

                    <Box sx={{
                        display: "flex",
                        height: "auto",
                        justifyContent: isMobile ? "center" : "center",
                        margin: isMobile ? "0 auto" : "50px 0 40px 0",
                        flexWrap: "wrap",
                    }}>
                        {store.isLoading ?
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                <CircularProgress/>
                            </Box> :
                            <>
                                {users && users.map((e, i) =>
                                    <Card onClick={() => personHandler(e)} key={e.FullName + i} sx={{
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: isMobile ? "295px" : "175px",
                                        height: isMobile ? "295px" : "155px",
                                        boxShadow: "4px 4px 5px 0px rgba(116, 134, 212, 0.25)",
                                        border: "1px solid #4D4D4D",
                                        borderRadius: "10px",
                                        backgroundColor: "rgba(116, 134, 212, 0)",
                                        margin: isMobile ? "25px 0" : "10px 7px",
                                        cursor: "pointer"
                                    }}>
                                        <Avatar variant="square" alt="Remy Sharp" src={e.Exension ?
                                            `data:image/${e.Exension};base64,${e.Image}` : avatarImg
                                        }
                                                sx={{
                                                    borderRadius: "10px",
                                                    width: isMobile ? "245px" : "145px",
                                                    height: isMobile ? "245px" : "125px",

                                                }}
                                        />
                                        <Box sx={{
                                            position: "absolute",
                                            borderRadius: "10px",
                                            width: isMobile ? "245px" : "145px",
                                            height: isMobile ? "245px" : "125px",
                                            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #101452 100%)",
                                        }}></Box>
                                        <Typography dir="rtl" variant="body1" color="text.secondary"
                                                    sx={{
                                                        position: "absolute",
                                                        bottom: isMobile ? "30px" : "20px",
                                                        right: isMobile ? "30px" : "20px",
                                                        color: "white"
                                                    }}>
                                            <strong>{e.FullName}</strong>
                                        </Typography>
                                    </Card>
                                )}
                            </>}
                    </Box>
                    <Box dir={store.isEnglish?"rtl":"ltr"} sx={{
                        display: store.isLoading ? "none" : "flex",
                        justifyContent: isMobile ? "center" : "end",
                    }}>
                        <Pagination
                            showFirstButton
                            showLastButton
                            renderItem={(item) => (
                                <PaginationItem
                                    sx={{fontSize: '16px'}}
                                    slots={{
                                        first: store.isEnglish? FirstPageIcon:LastPageIcon,
                                        last: store.isEnglish?LastPageIcon:FirstPageIcon,
                                        previous: store.isEnglish?ChevronLeftIcon:ChevronRightIcon,
                                        next: store.isEnglish?ChevronRightIcon:ChevronLeftIcon
                                    }}
                                    {...item}/>)}
                            sx={{
                                direction: store.isEnglish?'ltr':'rtl',
                                fontSize: '16px',
                            }} page={store.page} count={pagesCount} shape="rounded"
                            onChange={handlePageChange}
                        />
                    </Box>
                    {store.isEnglish?<Box
                         sx={{
                             display: "flex",
                             justifyContent: isMobile ? "center" : "start",
                             margin: isMobile ? "unset" : 1,
                             fontSize: "12px"
                         }}>{`Result found: ${store.firstPersonIndex} - ${store.lastPersonIndex} of ${store.usersCount}`}
                    </Box>:
                    <Box
                         sx={{
                             display: "flex",
                             justifyContent: isMobile ? "center" : store.isEnglish?"start":"end",
                             margin: isMobile ? "unset" : 1,
                             fontSize: "12px"
                         }}>{` מתוך ${store.firstPersonIndex} - ${store.lastPersonIndex} תוצאות נמצאו: ${store.usersCount}`}
                    </Box>}
                </div>
            </Box>
        </Paper>
    );
}

export default observer(MainPage);