import * as React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddPersonForm from "../../../components/AddPersonForm";
import {observer} from "mobx-react-lite";
import {useContext, useEffect} from "react";
import {Context} from "../../../index";
import avatarImg from "../../../assets/avatar.png";
import {Avatar, Box} from "@mui/material";
import {PersonType} from "../../../store/store";
import {useNavigate} from "react-router-dom";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: 600,
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '10px',
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#DCEEF9',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        transition:'1s',
    }
}));

const TabContainer = () => {
    const {store} = useContext(Context)
    const rows = store.users;
    const navigate = useNavigate();

    const personHandler = (person: PersonType) => {
        store.setPerson(person)
        navigate('/person')
    }


    useEffect(() => {
        if (!store.users.length) {
            store.getPersons("", 1)
        }
    }, [])

    return (
        <TableContainer >
            <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                        <StyledTableCell align="right">Full Name</StyledTableCell>
                        <StyledTableCell align="right">Age</StyledTableCell>
                        <StyledTableCell align="right">Location</StyledTableCell>
                        <StyledTableCell align="right">Description</StyledTableCell>
                        <StyledTableCell align="right">Email</StyledTableCell>
                        <StyledTableCell align="right">Phone number</StyledTableCell>
                        <StyledTableCell align="right">Data</StyledTableCell>
                        <StyledTableCell align="right">Status</StyledTableCell>
                        <StyledTableCell align="right">Actions(g)</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <StyledTableRow key={row.FullName} onClick={() => personHandler(row)}>
                            <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                            <StyledTableCell align="right">
                                <Avatar alt="Photo" src={row.Exension ?
                                    `data:image/${row.Exension};base64,${row.Image}` : avatarImg
                                }
                                        sx={{
                                            width: "40px",
                                            height: "40px",
                                            margin: 0,
                                            padding: 0
                                        }}
                                />
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.FullName}</StyledTableCell>
                            <StyledTableCell align="right">{row.Age}</StyledTableCell>
                            <StyledTableCell align="right">{row.Location}</StyledTableCell>
                            <StyledTableCell align="right">{row.Description}</StyledTableCell>
                            <StyledTableCell align="right">{row.Email}</StyledTableCell>
                            <StyledTableCell align="right">{row.Phone}</StyledTableCell>
                            <StyledTableCell align="right">{row.LastSeen}</StyledTableCell>
                            <StyledTableCell align="right">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '6px 24px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        borderRadius: '20px',
                                        color:'#FFF',
                                        background: '#31893A',
                                    }}
                                >Reported</Box>
                            </StyledTableCell>
                            <StyledTableCell align="right">Action</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default observer(TabContainer)