import React, {FC, useContext} from 'react';
import {
    Box,
    Button,
    Typography,
    useMediaQuery, useTheme,
} from "@mui/material";
import AddPersonForm from "../components/AddPersonForm";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import LoginForm from "../components/LoginForm";
import flagUk from "../assets/flagUK.svg";
import flagIsrael from "../assets/flagIsrael.svg";
import changeIcon from "../assets/change.svg";
import {Context} from "../index";
import {Simulate} from "react-dom/test-utils";
import change = Simulate.change;


const Login: FC = () => {
    const {store} = useContext(Context)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const changeLanguageHandler = () => {
        store.setIsEnglish(!store.isEnglish)
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: '100vh',
            boxSizing: 'border-box',
            padding: 0,
            alignItems: "center",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: isMobile ? 'unset' : '670px',
                width: isMobile ? '100vw' : 'unset',
                height: '780px',
                boxSizing: 'border-box',
                backgroundColor: "#FFF",
                borderRadius: isMobile ? "0" : "50px",
                boxShadow: "0px 0px 50px 0px rgba(64, 63, 63, 0.30)",
                padding: '135px 55px 165px 55px',
                marginLeft: isMobile ? 0 : "30%",
            }}>

                <Box sx={{
                    display: 'flex',
                    transform: store.isEnglish ? 'unset' : 'scaleX(-1);',
                    justifyContent: 'center',
                    transition: '0.3s',
                }} onClick={changeLanguageHandler}>
                    <img style={{margin: '10px'}} src={flagIsrael} alt="flagIsrael"/>
                    <img style={{margin: '10px'}} src={changeIcon} alt="change"/>
                    <img style={{margin: '10px'}} src={flagUk} alt="flagUk"/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '65px',
                    margin: '30px 0',
                }}>
                    <Typography sx={{
                        color: '#000',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: '24px',
                        fontWeight: '400',
                    }}>
                        Sign in
                    </Typography>
                </Box>

                <LoginForm/>
            </Box>
        </Box>
    );
}

export default observer(Login);
