import {makeAutoObservable} from "mobx";
import axios from 'axios';
import Services from "../services/Services";
import {Simulate} from "react-dom/test-utils";
import focus = Simulate.focus;

type RequestStatusType = 'empty'| 'success'| 'waiting'| 'error'
export type PersonType = {
    Additional: string
    Age: string
    Description: string
    Email: string
    Exension: string
    FullName: string
    Gender: string
    Image: string
    LastSeen: string
    Location: string
    Phone: string
}

const PERSON_IN_PAGE = 20
export default class Store {
    users = [] as PersonType[];
    person = {Additional: '',
        Age: '',
        Description: '',
        Email: '',
        Exension: '',
        FullName: '',
        Gender: '',
        Image: '',
        LastSeen: '',
        Location: '',
        Phone: ''}
    usersCount = 0;
    page = 1;
    searchValue = ''
    isLoading = false;
    // allReportsData = {} as AllReportsDateType
    isPending = false
    requestStatus:RequestStatusType = 'empty'
    isNewSession = true
    isAdmin = false
    isEnglish = false
    userName = ''
    firstPersonIndex = 1;
    lastPersonIndex = PERSON_IN_PAGE;

    constructor() {
        makeAutoObservable(this)
    }

    setFirstPersonIndex(index:number){
        this.firstPersonIndex = index
    }

    setLastPersonIndex(index:number){
        this.lastPersonIndex = index
    }

    setUserName(name:string){
        this.userName = name
    }
    setIsEnglish(bool:boolean){
        this.isEnglish = bool
    }

    setIsAdmin(bool:boolean){
        this.isAdmin = bool
    }

    setIsNewSession(bool:boolean){
        this.isNewSession = bool
    }

    setUsers(users: PersonType[]) {
        this.users = users
    }

    setPerson(person: PersonType) {
        this.person = person
    }

    setUsersCount(count:number){
        this.usersCount = count
    }

    setPage(page:number){
        this.page = page
    }

    setSearchValue(value:string){
        this.searchValue = value
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }
    setIsPending(bool:boolean){
        this.isPending = bool;
    }
    setRequestStatus(val:RequestStatusType){
        this.requestStatus = val;
    }

    async getPersons(word: string, page: number){
        try {
            this.setLoading(true)
            const response = await Services.getPersons({
                "word": word,
                "page": page
            });
            this.setUsers(response.data.users)
            this.setUsersCount(response.data.found)
            this.setFirstPersonIndex( (this.page>1)? (this.page-1) * PERSON_IN_PAGE + 1 : 1)
            this.setLastPersonIndex((this.firstPersonIndex + PERSON_IN_PAGE) > response.data.found ?
                response.data.found : this.firstPersonIndex + PERSON_IN_PAGE - 1)
        } catch (e: any) {
            console.log(e.response?.data?.message)
        } finally {
            this.setLoading(false)
        }
    }
}
