import React, {ChangeEvent, FC, useContext, useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Paper, Typography,
    useMediaQuery, useTheme,
} from "@mui/material";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import avatarImg from "../assets/avatar.png";
import {useNavigate} from "react-router-dom";
import cloud from "../assets/cloud.png";
import backgroundImg from "../assets/backgroundImg.png";
import flagIsrael from "../assets/flagIsrael.svg";
import changeIcon from "../assets/change.svg";
import flagUk from "../assets/flagUK.svg";

const PersonInfoPage: FC = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    let person = store.person;
    const goToMainPage = () => {
        navigate(-1);
    }
    const changeLanguageHandler = () => {
        store.setIsEnglish(!store.isEnglish)
    }

    useEffect(() => {

        if (!person.FullName.length) {
            const jsonStringFromStorage = localStorage.getItem('Person');
            console.log('jsonStringFromStorage', jsonStringFromStorage);
            if (jsonStringFromStorage) {
                store.setPerson(JSON.parse(jsonStringFromStorage))
            } else {
                // goToMainPage();
            }
        } else {
            localStorage.setItem('Person', JSON.stringify(person));
        }
    }, [])


    return (
        <Paper sx={{
            fontFamily: 'Century Gothic',
            display: "flex",
            justifyContent: 'center',
            minWidth: "100%",
            minHeight: '100vh',
            padding: '2rem',
            borderRadius: isMobile ? 'none' : '40px',
            background: '#EDEDED',
            color: "#4D4D4D",
        }}>
            <Box sx={{
                position: "relative",
                maxWidth: "1685px",
                height: "100%",
                margin: "1 auto",
                background: "rgba(255, 255, 255, 0.20)",
                borderRadius: isMobile ? 'none' : '40px',
                backgroundImage: `url(${cloud})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems:'center',
                    width: "100%",
                    minHeight: 'calc(100vh - 4rem)',
                    borderRadius: "30px",
                    border: "6px solid rgba(125, 125, 125, 0.20)",
                    position: "relative",
                    backgroundImage: `url(${backgroundImg}`,
                    backgroundSize: "cover",
                    backdropFilter: "blur(200px)",
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: isMobile ? 'unset' : '1685px',
                        width: isMobile ? '100vw' : 'unset',
                        height: '95%',
                        boxSizing: 'border-box',
                        borderRadius: isMobile ? "0" : "50px",
                        padding: isMobile ? 1 : 5,
                        marginTop: "10px",
                    }}>
                        <Box sx={{
                            display: "flex",
                            marginBottom: "30px",
                        }}>
                            <Button onClick={goToMainPage} variant="outlined"
                                    sx={{
                                        width: isMobile ? "100%" : "154px",
                                        height: "50px",
                                        borderRadius: "10px",
                                        textTransform: "none",
                                        fontSize: "16px",
                                        fontWeight: "700"
                                    }}>{store.isEnglish ? "← Back" : "←חזור"}</Button>

                            <Box sx={{
                                display: "flex",
                                justifyContent: 'end',
                                padding: "0 10px",
                                width: "100%",
                            }}>
                                <Box sx={{
                                    width: isMobile ? '100%':'191px',
                                    height: '48px', borderRadius: '10px', border: '1px solid #4D4D4D',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    transform: store.isEnglish ? 'unset' : 'scaleX(-1);',
                                    transition: '0.3s',
                                }} onClick={changeLanguageHandler}>
                                    <img style={{margin: '10px'}} src={flagIsrael} alt="flagIsrael"/>
                                    <img style={{margin: '10px'}} src={changeIcon} alt="change"/>
                                    <img style={{margin: '10px'}} src={flagUk} alt="flagUk"/>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: "flex",
                            flexDirection:  !store.isEnglish? "row-reverse" : "row",
                            justifyContent: store.isEnglish? "start" : "end",
                            flexWrap: "wrap",
                            alignItems: "center",
                            margin: isMobile ? 1:2
                        }}>
                            <Box dir={store.isEnglish ? "ltr" : "rtl"}
                                 sx={{maxWidth: "245px", margin: isMobile ? "0 auto" : "0 10px",}}>
                                <Avatar variant="square" alt="Photo" src={person.Exension ?
                                    `data:image/${person.Exension};base64,${person.Image}` : avatarImg
                                }
                                        sx={{
                                            width: isMobile ? "245px" : "245px",
                                            height: isMobile ? "245px" : "245px",
                                            marginBottom: 1,
                                            borderRadius: "10px",
                                        }}
                                />
                            </Box>
                            <Box dir={store.isEnglish ? "ltr" : "rtl"}
                                 sx={{
                                     margin: isMobile ? "0 auto" : "0 65px 0 0",
                                     textAlign: isMobile ? "center" : "unset",
                                 }}>
                                <Typography variant="h4"
                                            sx={{
                                                color: "#4D4D4D",
                                                borderBottom: "1px solid rgba(77, 77, 77, 0.20)",
                                                padding: "10px 0",
                                            }}>
                                    {person.FullName}
                                </Typography>
                                <Box sx={{
                                    color: "#4D4D4D",
                                    borderBottom: "1px solid rgba(77, 77, 77, 0.20)",
                                    padding: "10px 0",
                                }}>
                                    <Box dir={store.isEnglish ? "ltr" : "rtl"}
                                         sx={{
                                             display: "flex",
                                             width: isMobile ? "100%" : "unset",
                                             alignItems: "center"
                                         }}>
                                        <Typography sx={{textAlign: "center", fontWeight: "600", margin: "0 5px"}}
                                                    variant="body1">
                                            {store.isEnglish ? "Age:" : `גיל:`}
                                        </Typography>
                                        <Typography sx={{textAlign: "center"}} variant="body1">
                                            {person.Age}
                                        </Typography>
                                    </Box>
                                    <Box dir={store.isEnglish ? "ltr" : "rtl"}
                                         sx={{
                                             display: "flex",
                                             width: isMobile ? "100%" : "unset",
                                             alignItems: "center"
                                         }}>
                                        <Typography sx={{textAlign: "center", fontWeight: "600", margin: "0 5px"}}
                                                    variant="body1">
                                            {store.isEnglish ? "Gender:" : `מין:`}
                                        </Typography>
                                        <Typography sx={{
                                            textAlign: "center",
                                        }} variant="body1">
                                            {person.Gender}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box sx={{
                                    color: "#4D4D4D",
                                    padding: "10px 0",
                                }}>
                                    <Box dir={store.isEnglish ? "ltr" : "rtl"}
                                         sx={{display: "flex", alignItems: "center"}}>
                                        <Typography sx={{textAlign: "center", fontWeight: "600", margin: "0 5px"}}>
                                            {store.isEnglish ? "Email:" : `מייל:`}
                                        </Typography>
                                        <Typography sx={{textAlign: "center", fontSize: "14px"}}>
                                            {person.Email.split('').reverse().join('')}
                                        </Typography>
                                    </Box>
                                    <Box dir={store.isEnglish ? "ltr" : "rtl"}
                                         sx={{display: "flex", alignItems: "center"}}>
                                        <Typography sx={{textAlign: "center", fontWeight: "600", margin: "0 5px"}}>
                                            {store.isEnglish ? "Phone number:" : `מספר טלפון :`}
                                        </Typography>
                                        <Typography sx={{textAlign: "center", fontSize: "14px"}}>
                                            {person.Phone}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>


                        <Box dir={store.isEnglish ? "ltr" : "rtl"} sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom:"30px"}}>
                            <Box dir={store.isEnglish ? "ltr" : "rtl"}
                                 sx={{
                                     width: isMobile ? "100%" : "25%",
                                     padding: '10px',
                                     borderLeft: isMobile?"unset" : store.isEnglish ? "unset" : "1px solid rgba(77, 77, 77, 0.20)",
                                     borderRight: isMobile?"unset" : store.isEnglish ? "1px solid rgba(77, 77, 77, 0.20)" : "unset",
                                     borderTop: isMobile? "1px solid rgba(77, 77, 77, 0.20)":"unset",
                                 }}>
                                <Typography sx={{fontWeight: "600"}}
                                            variant="body1">
                                    {store.isEnglish ? "Last Known Location:" : `מיקום ידוע לאחרונה:`}
                                </Typography>
                                <Typography variant="body1">
                                    {person.Location}
                                </Typography>
                            </Box>

                            <Box dir={store.isEnglish ? "ltr" : "rtl"}
                                 sx={{
                                     width: isMobile ? "100%" : "25%",
                                     padding: '10px',
                                     borderLeft: isMobile?"unset" :store.isEnglish ? "unset" : "1px solid rgba(77, 77, 77, 0.20)",
                                     borderRight: isMobile?"unset" :store.isEnglish ? "1px solid rgba(77, 77, 77, 0.20)" : "unset",
                                     borderTop: isMobile? "1px solid rgba(77, 77, 77, 0.20)":"unset",
                                 }}>
                                <Typography sx={{ fontWeight: "600" }}
                                            variant="body1">
                                    {store.isEnglish ? "Last seen date and time: " : `זמן נראה לאחרונה:`}
                                </Typography>
                                <Typography variant="body1">
                                    {person.LastSeen}
                                </Typography>
                            </Box>

                            <Box dir={store.isEnglish ? "ltr" : "rtl"}
                                 sx={{
                                     width: isMobile ? "100%" : "25%",
                                     padding: '10px',
                                     borderTop: isMobile? "1px solid rgba(77, 77, 77, 0.20)":"unset",
                                 }}>
                                <Typography sx={{ fontWeight: "600"}}
                                            variant="body1">
                                    {store.isEnglish ? "Physical description:" : `תיאור פיזי:`}
                                </Typography>
                                <Typography variant="body1">
                                    {person.Description}
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{
                            padding: '10px',
                        }}>
                            <Typography dir={store.isEnglish ? "ltr" : "rtl"} sx={{fontWeight: "600"}} variant="body1"
                                        color="#4D4D4D">
                                {store.isEnglish ? "Other relevant information:" : `מידע נוסף רלוונטי:`}
                            </Typography>
                            <Typography dir={store.isEnglish ? "ltr" : "rtl"} variant="body1" color="#4D4D4D" sx={{
                                height: "100%",
                                width: isMobile ? 'unset' : '1000px',
                            }}>
                                {person.Additional}
                            </Typography>
                        </Box>

                    </Box>
                </div>
            </Box>
        </Paper>
    );
}

export default observer(PersonInfoPage);