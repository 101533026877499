import React, {FC, useContext, useRef, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel, MenuItem, NativeSelect,
    OutlinedInput,
    TextField, useMediaQuery, useTheme
} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import parsePhoneNumberFromString from "libphonenumber-js";
import s from "./AddPersonForm.module.css";
import Services from "../services/Services";
import {useNavigate} from "react-router-dom";
import ModalPersonAdded from "./ModalPersonAdded";
import {observer} from "mobx-react-lite";

const isValidPhoneNumber = (phoneNumber: string): boolean => {
    if (!phoneNumber) {
        return false; // Возвращаем false, если phoneNumber равен undefined или пустой строке
    }
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    return parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
};

const signInSchema = yup.object().shape({
    fullName: yup.string().trim().min(2, 'השם קצר מדי').max(50, 'השם ארוך מדי').required("זהו שדה חובה"),
    age: yup.string().trim(),
    gender: yup.string(),
    lastLocation: yup.string().trim(),
    lastSeenDate: yup.string().trim(),
    physicalDescription: yup.string().trim(),
    personsImage: yup.mixed().transform(value => (value === '' ? undefined : value)),
    phoneNumber: yup.string().trim().test('isValidPhoneNumber', 'מספר טלפון לא תקין', (value) => {
        return isValidPhoneNumber(value || ''); // Передаем пустую строку, если значение undefined
    }).required('זהו שדה חובה'),
    email: yup.string().trim().email('אנא בדוק את כתובת הדוא"ל שלך').required("זהו שדה חובה"),
    information: yup.string().max(500, 'המידע חייב להכיל לא יותר מ-500 תווים').trim(),
});

type FormData = {
    fullName: string;
    age?: string;
    gender?: string;
    lastLocation?: string;
    lastSeenDate?: string;
    physicalDescription?: string;
    personsImage?: any;
    phoneNumber: string;
    email: string;
    information?: string;
};


const AddReportForm: FC = () => {
    const [isAdded, setIsAdded] = useState(false)
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm<FormData>({
        resolver: yupResolver(signInSchema),
    });
    const [fileSizeError, setFileSizeError] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const [imgFile, setImgFile] = useState<any>(undefined);


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024); // Размер файла в MB
            if (fileSizeInMB > 5) {
                setFileSizeError('File size exceeds 5MB limit');
            } else {
                setFileSizeError(null);
                setImgFile(file)
                setFileName(file.name);
            }
        }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {

        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const addPerson = async (formData: any) => {
        try {
            const response = await Services.addPerson(formData);
            alert('נוספו נתונים');
            navigate(-1);
            // setIsAdded(true);
        } catch (e: any) {
            console.log(e.response?.data?.message)
            if (e.response) {
                // Ошибка при получении ответа с HTTP-статусом, отличным от 2xx
                console.log('Ошибка статуса:', e.response.status);
                console.log('Данные ошибки:', e.response.data);
            } else if (e.request) {
                // Ошибка при отправке запроса
                console.log('Ошибка запроса:', e.request);
            } else {
                // Остальные типы ошибок
                console.log('Ошибка:', e.message);
            }
            alert('מצטערים, לא נוספו נתונים. בעיות שרת אפשריות.')
        } finally {

        }
    }
    const onSubmit: SubmitHandler<FormData> = (data: any) => {
        const formData = {
            image: imgFile,
            full_name: data.fullName,
            age: data.age,
            gender: data.gender,
            location: data.lastLocation,
            last_seen: data.lastSeenDate,
            description: data.physicalDescription,
            phone: data.phoneNumber,
            email: data.email,
            additional: data.information,
        }
        addPerson(formData);
    };

    return (
        <>
            <form className={s.form}
                  onSubmit={handleSubmit(onSubmit)}
            >
                <Box sx={{display: 'flex', justifyContent:'space-between'}}>
                    <Box sx={{margin: '30px 0', width:'555px'}}>
                        <FormControl error={errors.fullName ? true : false}
                                     sx={{position: "relative", m: '1', width: '100%'}}
                                     variant="outlined">
                            <label htmlFor="input-name">שם מלא*</label>
                            <input dir="rtl" id="input-name" className={s.input_name} type='text'
                                   placeholder="הכנס שם מלא"
                                   {...register('fullName', {
                                       required: 'Name entered incorrectly',
                                   })}
                            />
                            {errors?.fullName &&
                                <FormHelperText id="component-error-name">{errors.fullName?.message}</FormHelperText>}
                        </FormControl>

                        <FormControl error={errors.age ? true : false} sx={{m: 1, width: '100%'}} variant="outlined">
                            <label htmlFor="input-age">גיל</label>
                            <input dir="rtl" id="input-age" className={s.input_age} type='text'
                                   placeholder="הכנס גיל"
                                   {...register('age', {
                                       required: '',
                                   })}
                            />
                            {errors?.age &&
                                <FormHelperText id="component-error-age">{errors.age?.message}</FormHelperText>}
                        </FormControl>


                        <FormControl error={errors.gender ? true : false} sx={{m: 1, width: '100%'}} variant="outlined">
                            <label htmlFor="input-gender">מין</label>
                            <select dir="rtl" id="input-gender" placeholder="בחר מין"
                                    {...register('gender', {
                                        required: '',
                                    })}>
                                <option value="זכר">זכר</option>
                                <option value="נקבה">נקבה</option>
                                <option value="" selected></option>
                            </select>
                            <div className={s.arrow}>&#9662;</div>

                        </FormControl>

                        <FormControl error={errors.lastLocation ? true : false} sx={{m: 1, width: '100%'}}
                                     variant="outlined">
                            <label htmlFor="input-location">מיקום ידוע אחרון</label>
                            <input id="input-location"
                                   dir="rtl"
                                   type='text'
                                   placeholder="הכנס מיקום"
                                   {...register('lastLocation', {
                                       required: '',
                                   })}
                            />
                        </FormControl>

                        <FormControl error={errors.lastSeenDate ? true : false} sx={{m: 1, width: '100%'}}
                                     variant="outlined">
                            <label htmlFor="input-date">זמן ותאריך ידוע אחרון</label>
                            <input id="input-date"
                                   dir="rtl"
                                   type='text'
                                   placeholder="הכנס זמן ותאריך"
                                   {...register('lastSeenDate', {
                                       required: '',
                                   })}
                            />
                        </FormControl>

                        <FormControl error={errors.physicalDescription ? true : false} sx={{m: 1, width: '100%'}}
                                     variant="outlined">
                            <label htmlFor="input-description">תיאור פיזי</label>
                            <input id="input-description"
                                   dir="rtl"
                                   type='text'
                                   placeholder='גובה , משקל , צבע שיער , צבע עיניים , גוון עור, סימנים מזהים וכו.'
                                   {...register('physicalDescription', {
                                       required: '',
                                   })}
                            />
                        </FormControl>

                        <FormControl error={errors.personsImage ? true : false} sx={{m: 1, width: '100%'}}
                                     variant="outlined">
                            <div className={s.file_input_container}>
                                <input dir="rtl" type="file" id="file-input"
                                       className={s.file_input}
                                       ref={fileInputRef}
                                       onChange={handleFileChange}
                                       accept=".jpg, .jpeg, .png"
                                />
                                <label htmlFor="file-input" className={s.custom_file_input_label}>
                                    {fileName ? fileName : 'תמונה'}
                                    <Button dir="rtl" variant="contained"
                                            onClick={handleButtonClick}
                                            sx={{
                                                position: "relative",
                                                right: "-50px",
                                                height: "80px",
                                                width: "90px",
                                                textTransform: "none"
                                            }}>בחר</Button>
                                </label>
                            </div>

                            {fileSizeError && <FormHelperText dir="rtl" error>{fileSizeError}</FormHelperText>}
                        </FormControl>

                    </Box>

                    <Box sx={{margin: '30px', width:'555px'}}>

                        <FormControl error={errors.phoneNumber ? true : false} sx={{m: 1, width: '100%'}}
                                     variant="outlined">
                            <label htmlFor="input-phoneNumber">מספר טלפון*</label>
                            <input id="input-phoneNumber"
                                   dir="rtl"
                                   type='tel'
                                   placeholder='הכנס מספר טלפון'
                                   {...register('phoneNumber', {
                                       required: 'מספר הטלפון שהוזן באופן שגוי',
                                   })}
                            />
                            {errors?.phoneNumber &&
                                <FormHelperText dir="rtl"
                                                id="component-error-phoneNumber">{errors.phoneNumber?.message}</FormHelperText>}
                        </FormControl>

                        <FormControl error={errors.email ? true : false} sx={{m: 1, width: '100%'}}
                                     variant="outlined">
                            <label htmlFor="input-email">מייל*</label>
                            <input id="input-email"
                                   dir="rtl"
                                   type='email'
                                   placeholder='הכנס כתובת מייל'
                                   {...register('email', {
                                       required: 'דואר אלקטרוני שהוזן באופן שגוי',
                                   })}
                            />
                            {errors?.email &&
                                <FormHelperText dir="rtl"
                                                id="component-error-phoneNumber">{errors.email?.message}</FormHelperText>}
                        </FormControl>

                        <FormControl error={errors.information ? true : false} sx={{m: 1, width: '100%'}}
                                     variant="outlined">
                            <label htmlFor="input-information">אדם נוסף שרלוונטי למקרה</label>
                            <input id="input-information"
                                   dir="rtl"
                                   type='text'
                                   {...register('information', {
                                       required: '',
                                   })}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <Button sx={{
                        margin: '20px',
                        width: '190px',
                        height: '60px',
                        backgroundColor:'gray',
                        color:'#000',
                        textTransform: "none",
                        borderRadius: "10px",
                        '&:hover':{backgroundColor:'lightgrey'}
                    }} variant="contained"
                            type="submit">Cancel</Button>
                    <Button sx={{
                        margin: '20px',
                        width: '190px',
                        height: '60px',
                        textTransform: "none",
                        borderRadius: "10px"
                    }} variant="contained"
                            type="submit">Save</Button>
                </Box>
            </form>
        </>
    )
}

export default observer(AddReportForm)




