import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';


const labelFontSize = "16px";
// A custom theme for this app
const theme = createTheme({
    // components: {
    //     MuiInputLabel: {
    //         styleOverrides: {
    //
    //             root: {
    //                 margin:"0 0 0 69%",
    //                 fontSize: labelFontSize
    //             }
    //         }
    //     },
    //     MuiOutlinedInput: {
    //         styleOverrides: {
    //             root: {
    //                 "& > fieldset > legend": {
    //                     margin:"0 0 0 70%",
    //                     fontSize: `16px`
    //                 }
    //             }
    //         }
    //     }
    // },
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
    palette: {
        primary: {
            main: '#4D4D4D',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;