import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel, MenuItem, NativeSelect,
    OutlinedInput,
    TextField, useMediaQuery, useTheme
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import parsePhoneNumberFromString from "libphonenumber-js";
import s from "./AddPersonForm.module.css";
import Services from "../services/Services";
import {useNavigate} from "react-router-dom";
import ModalPersonAdded from "./ModalPersonAdded";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const isValidPhoneNumber = (phoneNumber: string): boolean => {
    if (!phoneNumber) {
        return false; // Возвращаем false, если phoneNumber равен undefined или пустой строке
    }
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    return parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
};

const signInSchema = yup.object().shape({
    fullName: yup.string().trim().min(2, 'השם קצר מדי').max(50, 'השם ארוך מדי').required("זהו שדה חובה"),
    age: yup.string().trim(),
    gender: yup.string(),
    lastLocation: yup.string().trim(),
    lastSeenDate: yup.string().trim(),
    physicalDescription: yup.string().trim(),
    personsImage: yup.mixed().transform(value => (value === '' ? undefined : value)),
    phoneNumber: yup.string().trim().test('isValidPhoneNumber', 'מספר טלפון לא תקין', (value) => {
        return isValidPhoneNumber(value || ''); // Передаем пустую строку, если значение undefined
    }).required('זהו שדה חובה'),
    email: yup.string().trim().email('אנא בדוק את כתובת הדוא"ל שלך').required("זהו שדה חובה"),
    information: yup.string().max(500, 'המידע חייב להכיל לא יותר מ-500 תווים').trim(),
});

type FormData = {
    fullName: string;
    age?: string;
    gender?: string;
    lastLocation?: string;
    lastSeenDate?: string;
    physicalDescription?: string;
    personsImage?: any;
    phoneNumber: string;
    email: string;
    information?: string;
};

type PropsType = {
    callBack: () => void
}

const AddPersonForm: FC<PropsType> = ({callBack}) => {
    const {store} = useContext(Context)
    const [isAdded, setIsAdded] = useState(false)
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm<FormData>({
        resolver: yupResolver(signInSchema),
    });
    const [fileSizeError, setFileSizeError] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const [imgFile, setImgFile] = useState<any>(undefined);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [key, setKey] = useState(1);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024); // Размер файла в MB
            if (fileSizeInMB > 5) {
                setFileSizeError('File size exceeds 5MB limit');
            } else {
                setFileSizeError(null);
                setImgFile(file)
                setFileName(file.name);
            }
        }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);
    const form = useRef<HTMLFormElement>(null);
    const captchaRef = useRef<ReCAPTCHA>(null);

    const handleButtonClick = () => {

        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const addPerson = async (formData: any) => {
        try {
            const response = await Services.addPerson(formData);
            alert(store.isEnglish ? 'Data added to the database' : 'נוספו נתונים');
            navigate(-1);
            // setIsAdded(true);
        } catch (e: any) {
            console.log(e.response?.data?.message)
            if (e.response) {
                // Ошибка при получении ответа с HTTP-статусом, отличным от 2xx
                console.log('Ошибка статуса:', e.response.status);
                console.log('Данные ошибки:', e.response.data);
            } else if (e.request) {
                // Ошибка при отправке запроса
                console.log('Ошибка запроса:', e.request);
            } else {
                // Остальные типы ошибок
                console.log('Ошибка:', e.message);
            }
            alert(store.isEnglish ? 'Sorry. Server error. The data will not be added to the database.' : 'מצטערים, לא נוספו נתונים. בעיות שרת אפשריות.')
        } finally {
        }
    }
    const onSubmit: SubmitHandler<FormData> = (data: any) => {
        const token = captchaRef?.current?.getValue();
        const formData = {
            image: imgFile,
            full_name: data.fullName,
            age: data.age,
            gender: data.gender,
            location: data.lastLocation,
            last_seen: data.lastSeenDate,
            description: data.physicalDescription,
            phone: data.phoneNumber,
            email: data.email,
            additional: data.information,
            secret: token,
        }
        addPerson(formData);
    };

    useEffect(() => {
        // При изменении isEnglish обновляем key, что приведет к перерендерингу ReCAPTCHA
        setKey((prevKey) => prevKey + 1);
    }, [store.isEnglish]);

    return (
        <>
            <form className={s.form}
                  onSubmit={handleSubmit(onSubmit)}
            >
                <FormControl error={errors.fullName ? true : false}
                             sx={{position: "relative", width: '100%', marginBottom: '20px'}}
                             variant="outlined">
                    {store.isEnglish ?
                        <label htmlFor="input-name">Full Name*</label> :
                        <label htmlFor="input-name">'שם מלא*'</label>}
                    <input type="text" dir={store.isEnglish ? "ltr" : "rtl"} id="input-name"
                           className={s.input_name}
                           placeholder={store.isEnglish ? 'Enter full name' : "הכנס שם מלא"}
                           {...register('fullName', {
                               required: 'Name entered incorrectly',
                           })}
                    />
                    {errors?.fullName &&
                        <FormHelperText id="component-error-name">{store.isEnglish?"please enter the correct name":"נא להזין את השם הנכון"}</FormHelperText>}
                </FormControl>

                <FormControl error={errors.age ? true : false} sx={{width: '100%', marginBottom: '20px'}} variant="outlined">
                    {store.isEnglish ?
                        <label htmlFor="input-age">Age</label> :
                        <label htmlFor="input-age">גיל</label>}
                    <input dir={store.isEnglish ? "ltr" : "rtl"} id="input-age" className={s.input_age}
                           placeholder={store.isEnglish ? 'Enter age' : "הכנס גיל"}
                           {...register('age', {
                               required: '',
                           })}
                    />
                    {errors?.age &&
                        <FormHelperText id="component-error-age">{errors.age?.message}</FormHelperText>}
                </FormControl>


                <FormControl error={errors.gender ? true : false} sx={{width: '100%', marginBottom: '20px'}} variant="outlined">
                    {store.isEnglish ?
                        <label htmlFor="input-gender">Gender</label> :
                        <label htmlFor="input-gender">מין</label>}
                    <select dir={store.isEnglish ? "ltr" : "rtl"} id="input-gender" placeholder="בחר מין"
                            {...register('gender', {
                                required: '',
                            })}>
                        {store.isEnglish ? <option value="male">male</option> : <option value="זכר">זכר</option>}
                        {store.isEnglish ? <option value="female">female</option> : <option value="נקבה">נקבה</option>}

                        <option value="" selected></option>
                    </select>
                    <div className={s.arrow}>&#9662;</div>

                </FormControl>

                <FormControl error={errors.lastLocation ? true : false} sx={{width: '100%', marginBottom: '20px'}} variant="outlined">
                    {store.isEnglish ?
                        <label htmlFor="input-location">Last known location</label> :
                        <label htmlFor="input-location">מיקום ידוע אחרון</label>}
                    <input id="input-location"
                           dir={store.isEnglish ? "ltr" : "rtl"}
                           placeholder={store.isEnglish ? "Enter location" : "הכנס מיקום"}
                           {...register('lastLocation', {
                               required: '',
                           })}
                    />
                </FormControl>

                <FormControl error={errors.lastSeenDate ? true : false} sx={{width: '100%', marginBottom: '20px'}} variant="outlined">
                    {store.isEnglish ?
                        <label htmlFor="input-date">Last seen date and time</label> :
                        <label htmlFor="input-date">זמן ותאריך ידוע אחרון</label>}
                    <input id="input-date"
                           dir={store.isEnglish ? "ltr" : "rtl"}
                           placeholder={store.isEnglish ? "Enter date and time" : "הכנס זמן ותאריך"}
                           {...register('lastSeenDate', {
                               required: '',
                           })}
                    />
                </FormControl>

                <FormControl error={errors.physicalDescription ? true : false} sx={{width: '100%', marginBottom: '20px'}}
                             variant="outlined">
                    {store.isEnglish ?
                        <label htmlFor="input-description">Physical description</label> :
                        <label htmlFor="input-description">תיאור פיזי</label>}
                    <input id="input-description"
                           dir={store.isEnglish ? "ltr" : "rtl"}
                           placeholder={store.isEnglish ? "height, weight, hair color, eye color, distinguishing features, etc." : 'גובה , משקל , צבע שיער , צבע עיניים , גוון עור, סימנים מזהים וכו.'}
                           {...register('physicalDescription', {
                               required: '',
                           })}
                    />
                </FormControl>

                <FormControl error={errors.personsImage ? true : false} sx={{width: '100%'}} variant="outlined">
                    <div className={s.file_input_container}>
                        <input dir={store.isEnglish ? "ltr" : "rtl"} type="file" id="file-input"
                               className={s.file_input}
                               ref={fileInputRef}
                               onChange={handleFileChange}
                               accept=".jpg, .jpeg, .png"
                        />
                        {store.isEnglish ?
                            <label htmlFor="file-input" style={{
                                top:0,
                                right:0,
                                padding:'0 0 0 20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                                borderRadius: '10px 10px 10px 10px',
                                cursor: 'pointer',
                            }}>
                                <span style={{
                                    width:'75%',
                                    maxWidth:'75%',
                                    fontSize:'16px',
                                    color:"rgba(128,128,128,0.3)",
                                    whiteSpace: 'nowrap',
                                    textAlign:"left",
                                    overflow:'hidden',
                                    textOverflow:'ellipsis',
                                }}>{fileName ? fileName:'Select image'}</span>
                                <Button variant="contained"
                                        onClick={handleButtonClick}
                                        sx={{
                                            height: "75px",
                                            width: "90px",
                                            textTransform: "none"
                                        }}>Select</Button>
                            </label> :
                            <label htmlFor="file-input" style={{
                                top:0,
                                right:0,
                                padding:'0 0 0 20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                                borderRadius: '10px 10px 10px 10px',
                                cursor: 'pointer',
                            }}>
                                <span dir="rtl" style={{
                                    width:'75%',
                                    maxWidth:'75%',
                                    fontSize:'16px',
                                    color:"rgba(128,128,128,0.3)",
                                    whiteSpace: 'nowrap',
                                    textAlign:"right",
                                    overflow:'hidden',
                                    textOverflow:'ellipsis',
                                    paddingRight:'20px',
                                }}>{fileName ? fileName.split('').reverse().join(''):'הוסף תמונה'}</span>

                                <Button  variant="contained"
                                        onClick={handleButtonClick}
                                         sx={{
                                             height: "75px",
                                             width: "90px",
                                             textTransform: "none"
                                         }}>בחר</Button>
                            </label>}
                    </div>

                    {fileSizeError &&
                        <FormHelperText dir={store.isEnglish ? "ltr" : "rtl"} error>{fileSizeError}</FormHelperText>}
                </FormControl>

                <FormControl error={errors.phoneNumber ? true : false} sx={{width: '100%', marginBottom: '20px'}}
                             variant="outlined">
                    {store.isEnglish ?
                        <label htmlFor="input-phoneNumber">Phone number*</label> :
                        <label htmlFor="input-phoneNumber">מספר טלפון*</label>}
                    {store.isEnglish ?
                        <input id="input-phoneNumber"
                               dir={store.isEnglish ? "ltr" : "rtl"}
                               type='tel'
                               placeholder={store.isEnglish ? "Enter phone number" : 'הכנס מספר טלפון'}
                               {...register('phoneNumber', {
                                   required: 'Phone number entered incorrectly',
                               })}
                        /> :
                        <input id="input-phoneNumber"
                               dir={store.isEnglish ? "ltr" : "rtl"}
                               type='tel'
                               placeholder={store.isEnglish ? "Enter phone number" : 'הכנס מספר טלפון'}
                               {...register('phoneNumber', {
                                   required: 'מספר הטלפון שהוזן באופן שגוי',
                               })}
                        />}
                    {errors?.phoneNumber &&
                        <FormHelperText dir={store.isEnglish ? "ltr" : "rtl"}
                                        id="component-error-phoneNumber">{store.isEnglish?"please enter the correct phone number":"אנא הזן את מספר הטלפון הנכון"}</FormHelperText>}
                </FormControl>

                <FormControl error={errors.email ? true : false} sx={{width: '100%', marginBottom: '20px'}}
                             variant="outlined">
                    {store.isEnglish ?
                        <label htmlFor="input-email">Email*</label> :
                        <label htmlFor="input-email">מייל*</label>}
                    {store.isEnglish ?
                        <input id="input-email"
                               dir={store.isEnglish ? "ltr" : "rtl"}
                               type='email'
                               placeholder='Enter email'
                               {...register('email', {
                                   required: 'Email entered incorrectly',
                               })}
                        /> :
                        <input id="input-email"
                               dir={store.isEnglish ? "ltr" : "rtl"}
                               type='email'
                               placeholder='הכנס כתובת מייל'
                               {...register('email', {
                                   required: 'דואר אלקטרוני שהוזן באופן שגוי',
                               })}
                        />}
                    {errors?.email &&
                        <FormHelperText dir={store.isEnglish ? "ltr" : "rtl"}
                                        id="component-error-phoneNumber">{store.isEnglish?"please enter the correct email":"אנא הזן את הדוא \" ל הנכון"}</FormHelperText>}
                </FormControl>

                <FormControl error={errors.information ? true : false} sx={{width: '100%', marginBottom: '20px'}}
                             variant="outlined">
                    {store.isEnglish ?
                        <label htmlFor="input-information">Any other relevant information</label> :
                        <label htmlFor="input-information">הוסף אדם נוסף</label>}
                    <textarea id="input-information"
                              dir={store.isEnglish ? "ltr" : "rtl"}
                              {...register('information', {
                                  required: '',
                              })}
                    />
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        m: 2,
                    }}
                >
                    <ReCAPTCHA
                        key={key}
                        onChange={(newToken) => {
                            setIsCaptchaValid(!!newToken);
                        }}
                        theme="dark"
                        hl={store.isEnglish ? "en" : "he"}
                        ref={captchaRef}
                        sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button onClick={callBack}
                            sx={{
                                marginTop: '30px',
                                marginRight: '20px',
                                width: '100%',
                                height: '50px',
                                textTransform: "none",
                                backgroundColor: "rgb(0,0,0,0)",
                                borderRadius: "10px",
                                border: "1px solid #4D4D4D",
                                color: '#4D4D4D"',
                            }}
                    >{store.isEnglish ? "Cancel" : "ביטול"}</Button>

                    <Button
                        disabled={!isCaptchaValid}
                        sx={{
                            marginTop: '30px',
                            width: '100%',
                            height: '50px',
                            textTransform: "none",
                            borderRadius: "10px",
                        }} variant="contained"
                        type="submit">{store.isEnglish ? "Add report" : "הוסף"}</Button>

                </Box>

            </form>
        </>
    )
}

export default observer(AddPersonForm)