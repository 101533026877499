import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel, TextField,

} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {redirect, useNavigate} from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {makeStyles} from '@mui/styles';
import {Context} from "../index";
import {observer} from "mobx-react-lite";


const signInSchema = yup.object().shape({
    userName: yup.string().trim().min(2, 'The name must be more than one character').max(50, 'The name must be less than 50 characters').required("This field is required"),
    password: yup.string().trim(),
});

type FormData = {
    userName: string;
    password?: string;
};

// const useStyles = makeStyles((theme) => ({}))

const LoginForm: FC = () => {
    // const classes = useStyles();
    const {store} = useContext(Context)
    const navigate = useNavigate();

    const {register, handleSubmit, formState: {errors}} = useForm<FormData>({
        resolver: yupResolver(signInSchema),
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onSubmit: SubmitHandler<FormData> = (data: any) => {
        const formData = {
            userName: data.userName,
            password: data.password,
        }
        if (formData.userName === 'admin') {
            store.setIsAdmin(true)
        }
        store.setUserName(formData.userName)
    };

    useEffect(() => {
        if (store.userName)
            if (store.userName === 'admin') {
                navigate('/reports')
            } else {
                navigate('/persons')
            }
    }, [store.userName])


    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormControl sx={{
                    marginBottom: '30px',
                    width: '100%',
                    position: "relative",
                }}
                             variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-userName"
                                sx={{
                                    position: 'absolute',
                                    top: '-28px',
                                    right: '25px',
                                    borderRadius: '40px',
                                }}>
                        <span
                            style={{
                                color: '#787878',
                                zIndex: "10",
                                fontSize: '14px',
                                padding: "0 25px",
                                backgroundColor: '#FFF',
                            }}

                        >
                            Username*
                        </span>
                    </InputLabel>
                    <TextField id="outlined-adornment-userName"
                               placeholder='Enter Username'
                               type='text'
                               {...register('userName', {
                                   required: 'Please, enter Username',
                               })}
                    />
                    {errors?.userName &&
                        <FormHelperText sx={{position: 'absolute', bottom: '-20px', color: 'red',}}
                                        id="component-error-text">
                            {errors.userName?.message}
                        </FormHelperText>}
                </FormControl>

                <FormControl
                    sx={{
                        width: '100%',
                        marginBottom: '30px',
                        position: "relative",
                    }}
                    error={!!errors.userName} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password"
                                sx={{
                                    position: 'absolute',
                                    top: '-28px',
                                    right: '25px',
                                    borderRadius: '40px',
                                }}
                    ><span
                        style={{
                            color: '#787878',
                            zIndex: "10",
                            fontSize: '14px',
                            padding: "0 25px",
                            backgroundColor: '#FFF',
                        }}
                    >Password</span></InputLabel>
                    <TextField
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Enter password'
                        {...register('password', {
                            required: 'Please, enter Username',
                        })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        tabIndex={-1}
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {errors?.password &&
                        <FormHelperText sx={{position: 'absolute', bottom: '-20px', color: 'red',}}
                                        id="component-error-password">{errors.password?.message}</FormHelperText>}
                </FormControl>
                <Button sx={{
                    display: 'flex',
                    height: '50px',
                    width: '100%',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    textTransform: 'none',
                    ontSize: '16px',
                    fontWeight: 500,
                    background: '#404040',
                    marginTop: '40px',
                    '&:hover': {
                        backgroundColor: 'grey',
                    },
                }}
                        variant="contained"
                        type="submit">Sign in</Button>
            </form>
        </>
    )
}

export default observer(LoginForm);




